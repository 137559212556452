<template>
    <div>
        <div class="modal fade" id="ProductionCardStatusModal" tabindex="-1" aria-labelledby="ProductionCardStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="ProductionCardStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{ProductionCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                       
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import productionCardServices from '../Script/ProductionCardServices.js';
import { response } from '../../../../infrastructure/constant/response';
import { production, sweet_alert } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionCardStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : '',
            Status : '',
            StatusData : [],
            ProductionCode: '',
        }
    },
    methods: {
        async statusClick(data){
            this.ModalTitle = 'Edit Status Production Card: ';
            this.Id = data.production_id;
            this.ProductionCode = data.production_code;
            
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_production');
            this.Status = data.status;
            
            window.$('#ProductionCardStatusModal').modal('show');
        },
        async saveClick(){
            var data = await productionCardServices.getDetailProductionCard(this.Id);
            
            var materialgridData = productionCardServices.formulaCount(data.production_material, "Edit");
            var materialData = this.$globalfunc.objectToArrayConverter(materialgridData, "Production-ProductionMaterial");
            
            var producerId = null;
            if(data.production_type == production.external.toString()){
                producerId = parseInt(data.producer_id)
            }
            
            const productionData = {
                production_code: data.production_code,
                status : this.Status,
                production_result_item_id: data.production_result_item_id,
                production_result_quantity: parseFloat(data.production_result_quantity),
                production_material: materialData,
                production_type: data.production_type.toString(),
                storage_id: data.storage_id,
                machine_id: parseInt(data.machine_id),
                waste_percentage: parseFloat(data.waste_percentage),
                booking_order_id: data.booking_order_id == null ? null : parseInt(data.booking_order_id),
                producer_id: producerId,
                production_actual_quantity: data.production_actual_quantity,
                production_reject_item_id: data.production_reject_item_id,
                production_reject_quantity: data.production_reject_quantity
            }
            
            const variables = {
                id : parseInt(this.Id),
                data : productionData
            };
            
            this.$swal(sweet_alert.update_status).then((result) => {
                if (result.isConfirmed == true) {
                    productionCardServices.editQuery(variables).then(res => {
                        window.$('#ProductionCardStatusModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>