<template>
    <div>
        <router-view></router-view>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Production Card</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="row">
                    <div class="col-6 text-light pb-2">
                        <button id="externalSort" class="btn btn-secondary" @click="externalClick()">
                            External
                        </button>
                        &nbsp;   
                        <button id="internalSort" class="btn btn-secondary" @click="internalClick()">
                            Internal
                        </button>
                        &nbsp;|&nbsp;   
                        <button id="add" class="btn btn-success" @click="addInternalClick()">
                            <i class="fa fa-plus"></i> Add Internal
                        </button>
                        &nbsp;    
                        <button id="add" class="btn btn-success" @click="addExternalClick()">
                            <i class="fa fa-plus"></i> Add External
                        </button>
                    </div>
                </div>

                <production-card-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick" :type="Type"/>
                <production-card-status-form ref="ProductionCardStatusForm" :reload="reload" />
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import productionCardGrid from './Grid/ProductionCardGrid.vue';
import productionCardServices from './Script/ProductionCardServices';
import productionCardStatusForm from '../ProductionCard/Component/ProductionCardStatusForm.vue';
import { response } from '../../../infrastructure/constant/response';
import { production, sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'ProductionCard',
    components: {
        'production-card-grid': productionCardGrid,
        'production-card-status-form': productionCardStatusForm
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Production Card');
        document.getElementById("add").disabled = permission.cperms ? false : true;

        this.statusFilter();

        // //Item Booking Order passing data
        // if (this.FormType == 'BookingOrder') {
        //     var data = this.$route.params.formData;
        //     this.bookingClick(data);
        // }
    },
    data(){
        return{
            gridReload: 0,
            // FormType: this.$route.params.formType,
            statusButton: '',
            Type: production.internal.toString(),
        }
    },
    methods: {
        internalClick() {
            this.Type = production.internal.toString();
            this.reload();
        },
        externalClick() {   
            this.Type = production.external.toString();  
            this.reload();
        },
        addInternalClick(){
            this.$router.push({ name: 'Production Card Form', params: {  formtype:'Add', id:' ', view:'new', type:production.internal, status:'New', bookingitemid:' ' } })
        },
        addExternalClick(){
            this.$router.push({ name: 'Production Card Form', params: {  formtype:'Add', id:' ', view:'new', type:production.external, status:'New', bookingitemid:' ' } })
        },
        bookingClick(data){     
            // this.$refs.productionCardForm.bookingOrderClick(data);
        },
        editClick(data, view){
            // this.$router.push({ name: 'Production Card Form', params: { formtype:'Edit', id:data.production_id, view:view, type:data.production_type, status:data.status, bookingitemid:' ' } });
            const routeData = this.$router.resolve({ name: 'Production Card Form', params: { formtype:'Edit', id:data.production_id, view:view, type:data.production_type, status:data.status, bookingitemid:' ' } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await productionCardServices.getDetailProductionCard(id);

            this.$swal(sweet_alert.delete).then((result) => {
               if (result.isConfirmed == true) {
                    var materialgridData = productionCardServices.formulaCount(data.production_material, "Edit");
                    var materialData = this.$globalfunc.objectToArrayConverter(materialgridData, "Production-ProductionMaterial");
                    
                    var producerId = null;
                    if(data.production_type == production.external.toString()){
                        producerId = parseInt(data.producer_id)
                    }

                    const productionData = {
                        production_code: data.production_code,
                        status: "Delete",
                        production_result_item_id: data.production_result_item_id,
                        production_result_quantity: parseFloat(data.production_result_quantity),
                        production_material: materialData,
                        production_type: data.production_type.toString(),
                        storage_id: data.storage_id,
                        machine_id: parseInt(data.machine_id),
                        waste_percentage: parseFloat(data.waste_percentage),
                        booking_order_id: data.booking_order_id == null ? null : parseInt(data.booking_order_id),
                        producer_id: producerId,
                        production_actual_quantity: data.production_actual_quantity,
                        production_reject_item_id: data.production_reject_item_id,
                        production_reject_quantity: data.production_reject_quantity
                    }

                    const variables = {
                        id : parseInt(id),
                        data : productionData
                    };
                    
                    productionCardServices.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");  
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.ProductionCardStatusForm.statusClick(data);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {    
            // var countStatus = await this.$globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_production', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnApproved").on('click', function() {
                    vue.$refs.grid.changeStatus('Approved');
                });
                $("#btnOnProgress").on('click', function() {
                    vue.$refs.grid.changeStatus('On Progress');
                });
                $("#btnFinish").on('click', function() {
                    vue.$refs.grid.changeStatus('Finish');
                });
                $("#btnCancel").on('click', function() {
                    vue.$refs.grid.changeStatus('Cancel');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
            })
        },
    }
}
</script>