<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            v-on:databound="dataBound"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import productionCardServices from '../Script/ProductionCardServices';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { production } from '../../../../infrastructure/constant/variable';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'ProductionCardGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'type'],
    data: function () {
        var type = this.$props.type;
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: 50,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                const paging = {
                                    Page : {Limit: e.pageSize,Page: e.page},
                                    Filter : globalfunc.filterQueryBuilder(e.filter),
                                    OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                                }
                                const variables = {
                                    paging : paging,
                                    type : type
                                }
                                return { query: productionCardServices.readProductionCardQuery(),
                                         variables: variables};
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetProduction.ProductionResponse == null){
                                return [];
                            }else{
                                return response.data.GetProduction.ProductionResponse;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetProduction.ProductionResponse == null){
                                return 0;
                            }else{
                                return response.data.GetProduction.Total;
                            }
                        },
                        model: {
                            fields: {
                                production_result_quantity: {type: "number"},
                                waste_percentage: {type: "number"},
                                created_at: {type: "date"},
                            }
                        }
                    },
                    sort: { field: "created_at", dir: "desc" }
                }),
            columns:  [
                { title: "Action", width: 150, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", width: 200,title: "Status", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "production_type", width: 200, title: "Tipe Produksi", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: '#= production_type == 1 ? "Internal" : "External" #' },
                { field: "production_code", width: 250, title: "Kode Produksi", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_result_product_code", width: 200,title: "Kode Produk", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_result_item_name", width: 200,title: "Nama Produk", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "machine_name", width: 200,title: "Mesin", headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "production_result_quantity", width: 200, title: "Quantity", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} }, 
                { field: "storage_id", width: 200, title: "Gudang", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "waste_percentage", width: 200, title: "Persentasi Waste", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "producer_name", width: 200, title: "Nama Producer", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: '#= production_type == 1 ? "Internal" : producer_name #' },
                { field: "created_at", width: 200, title: "Tanggal Dibuat", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #", attributes:{style: "text-align:center;"} }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            deleteClick(dataItem.production_id);
        })

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, true);
        })
        
        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            statusClick(dataItem);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Production Card")
        },
        detailInit: function (e) {
            var detailData = productionCardServices.formulaCount(e.data.production_material, "Edit");
            
            var column = [
                { field: 'type_detail_id', title: "Type Detail", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, hidden:true,
                    groupHeaderTemplate: "#= value # <br> Target Quantity: #= aggregates.total_quantity.max # <br> Total #= value # Quantity: #= aggregates.qty.sum #" },
                { field: 'product_code', title: "Kode Material", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: 'item_name', title: "Nama Material", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                { field: 'qty', title: "Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}},
            ];

            if(e.data.production_type == production.internal && e.data.status == 'Finish'){
                column = [
                    { field: 'type_detail_id', title: "Type Detail", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, hidden:true,
                        groupHeaderTemplate: "#= value # <br> Target Quantity: #= aggregates.total_quantity.max # <br> Total #= value # Quantity: #= aggregates.qty.sum #" },
                    { field: 'product_code', title: "Kode Material", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                    { field: 'item_name', title: "Nama Material", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }},
                    { field: 'qty', title: "Quantity", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}},
                    { field: 'actual_quantity', title: "Actual Quantity", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}},
                ];
            }
            
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "detailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(detailData);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify(
                            {
                                query: options.query,
                                variables: options.variables
                            }
                        );
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return []
                            else
                                return response
                        },
                        total: function(response) {
                            if(response == null)
                                return 0
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize,
                    group: [{ field: 'type_detail_id', aggregates:[{ field: 'qty', aggregate: 'sum' }, { field: 'total_quantity', aggregate: 'max' }]}],
                    
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                editable: false,
                resizable: true,
                columns: column,
            });
        },
        dataBound: function (e) {
            // ev.sender.expandRow(e.sender.tbody.find('tr.k-master-row').first())
        },
        changeStatus(status) {
            var type = this.$props.type;
            this.dataSource = new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                status : status,
                                type : type
                            }
                            return { query: productionCardServices.readProductionCardQuery(),
                                        variables: variables};
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetProduction.ProductionResponse == null){
                            return [];
                        }else{
                            return response.data.GetProduction.ProductionResponse;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetProduction.ProductionResponse == null){
                            return 0;
                        }else{
                            return response.data.GetProduction.Total;
                        }
                    },
                    model: {
                        fields: {
                            production_result_quantity: {type: "number"},
                            waste_percentage: {type: "number"},
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            })
        }
    }
}
</script>