<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Production Card : {{ProductionCode}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionCode" v-model="ProductionCode" class="font-weight-bold"/>
                            <label id="errorProductionCode" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" :disabled="DisabledValue"/>
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Item</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Item" class="pb-3" :options="ItemData" v-model="Item" @input="onChangeItem" :disabled="ItemDisabled"/>
                            <label id="errorItem" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Mesin</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Machine" class="pb-3" :options="MachineData" v-model="Machine" @input="onChangeMachine" :disabled="MachineDisabled"/>
                            <label id="errorMachine" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Gudang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <v-select id="Storage" class="pb-3" :options="StorageData" v-model="Storage" :disabled="DisabledValue"/>
                            <label id="errorStorage" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Quantity</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="Quantity" v-model="Quantity" class="font-weight-bold form-control input-number" @change="onChangeItem"/>
                            <label id="errorQuantity" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Persentasi Waste</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="WastePercentage" v-model="WastePercentage" class="font-weight-bold form-control input-number" @change="onChangeItem" readonly/>
                            <label id="errorWastePercentage" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Booking Order</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="BookingOrderCode" v-model="BookingOrderCode" class="font-weight-bold" readonly/>
                            <label id="errorBookingOrderCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label" v-if="this.Type == this.External">
                            <label class="form-label font-weight-bold">Producer</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content" v-if="this.Type == this.External">
                            <v-select id="Producer" class="pb-3" :options="ProducerData" v-model="Producer"/>
                            <label id="errorProducer" class="form-error" style="display: none; color: red;"></label>
                        </div>
                        <!-- End -->
                        <div class="col-sm-label" v-if="(this.ParamStatus == 'New')">
                            <label class="form-label font-weight-bold required">Estimasi Tanggal Mulai</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content" v-if="(this.ParamStatus == 'New')">
                            <date-picker
                                v-model="StartDateEstimate"
                                format="DD MMM YYYY HH:mm:ss"
                                type="datetime"
                                placeholder="Tanggal Mulai"
                                value-type="YYYY-MM-DD HH:mm:ss"
                                style="width:100%;"
                                v-bind:clearable="false"
                            ></date-picker>
                            <label id="errorStartDateEstimate" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label" v-if="(this.ParamStatus == 'New')">
                            <label class="form-label font-weight-bold required">Estimasi Tanggal Selesai</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content" v-if="(this.ParamStatus == 'New')">
                            <date-picker
                                v-model="EndDateEstimate"
                                format="DD MMM YYYY HH:mm:ss"
                                type="datetime"
                                placeholder="Tanggal Akhir"
                                value-type="YYYY-MM-DD HH:mm:ss"
                                style="width:100%;"
                                v-bind:clearable="false"
                            ></date-picker>
                            <label id="errorFinishDateEstimate" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label" v-if="(this.ParamStatus == 'On Progress' || this.ParamStatus == 'Approved')">
                            <label class="form-label font-weight-bold">Tanggal Mulai Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content" v-if="(this.ParamStatus == 'On Progress') || this.ParamStatus == 'Approved'">
                            <date-picker
                                :disabled="ProductionStartDateDisabled"
                                v-model="ProductionStartDate"
                                readonly = "true"
                                format="DD MMM YYYY HH:mm:ss"
                                type="datetime"
                                placeholder="Tanggal Mulai Produksi"
                                value-type="YYYY-MM-DD HH:mm:ss"
                                style="width:100%;"
                                v-bind:clearable="false"
                            ></date-picker>
                            <label id="errorProductionStartDate" class="form-error" style="display: none; color: red;"></label>
                        </div>
                        <div class="col-sm-label" v-if="this.ParamStatus == 'On Progress' || (this.ParamStatus == 'Finish')">
                            <label class="form-label font-weight-bold">Tanggal Akhir Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content" v-if="this.ParamStatus == 'On Progress' || (this.ParamStatus == 'Finish')">
                            <date-picker
                                v-model="ProductionFinishDate"
                                format="DD MMM YYYY HH:mm:ss"
                                type="datetime"
                                placeholder="Tanggal Akhir Produksi"
                                value-type="YYYY-MM-DD HH:mm:ss"
                                style="width:100%;"
                                v-bind:clearable="false"
                            ></date-picker>
                            <label id="errorProductionFinishDate" class="form-error" style="display: none; color: red;"></label>
                        </div>
                        <!-- End -->
                        <div class="col-sm-label" v-if="this.Type == this.Internal && (this.ParamStatus == 'On Progress' || this.ParamStatus == 'Finish')">
                            <label class="form-label font-weight-bold">Actual Quantity</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content" v-if="this.Type == this.Internal && (this.ParamStatus == 'On Progress' || this.ParamStatus == 'Finish')">
                            <input type="number" id="ActualQuantity" v-model="ActualQuantity" class="font-weight-bold form-control input-number"/>
                            <label id="errorActualQuantity" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                        <div class="col-sm-label" v-if="this.Type == this.Internal && (this.ParamStatus == 'On Progress' || this.ParamStatus == 'Finish')">
                            <label class="form-label font-weight-bold">Reject Item</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content" v-if="this.Type == this.Internal && (this.ParamStatus == 'On Progress' || this.ParamStatus == 'Finish')">
                            <v-select id="RejectItem" class="pb-3" :options="RejectItemData" v-model="RejectItem"/>
                            <label id="errorRejectItem" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    <CRow v-if="this.Type == this.Internal && (this.ParamStatus == 'On Progress' || this.ParamStatus == 'Finish')">
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Reject Quantity</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <input type="number" id="RejectQuantity" v-model="RejectQuantity" class="font-weight-bold form-control input-number"/>
                            <label id="errorRejectQuantity" class="form-error" style="display: none; color: red;"></label>
                        </div>  
                    </CRow>
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Material</h4></label>
                            <hr>
                            <datasource ref="materialDataSource"    :data="this.MaterialGridData" 
                                                                    :page-size="10" 
                                                                    :schema-model-fields="this.MaterialSchemaModel" 
                                                                    :group="[{ field: 'type_detail_id', aggregates:[{ field: 'qty', aggregate: 'sum' }, { field: 'total_quantity', aggregate: 'max' }]}]"
                                                                    v-if="(this.ParamStatus != 'On Progress' && this.ParamStatus != 'Finish')"/>

                            <datasource ref="materialDataSource"    :data="this.MaterialGridData" 
                                                                    :page-size="10" 
                                                                    :schema-model-fields="this.MaterialSchemaModelFinish" 
                                                                    :group="[{ field: 'type_detail_id', aggregates:[{ field: 'qty', aggregate: 'sum' }, { field: 'total_quantity', aggregate: 'max' }]}]"
                                                                    v-if="(this.ParamStatus == 'On Progress' || this.ParamStatus == 'Finish')"/>

                            <kendo-grid ref="gridMaterial"
                                        :data-source-ref="'materialDataSource'"
                                        :pageable="true"
                                        :editable="EditableValue"
                                        :resizable="true"
                                        :key="GridReload"
                                        :cellClose="itemCellClose"
                                        >

                                <kendo-grid-column  :field="'type_detail_id'"
                                                    :title="'Type Detail'"
                                                    :width="150"
                                                    :hidden="true"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :groupHeaderTemplate ="'#= value # <br> Target Quantity: #= aggregates.total_quantity.max # <br> Total #= value # Quantity: #= aggregates.qty.sum #'"></kendo-grid-column>

                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode Material'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama Material'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'qty'"
                                                    :title="'Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                
                                <kendo-grid-column  :field="'actual_quantity'"
                                                    :title="'Actual Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"
                                                    v-if="this.Type == this.Internal && (this.ParamStatus == 'On Progress' || this.ParamStatus == 'Finish')"></kendo-grid-column>
                                <kendo-grid-column  :field="'ready_quantity'"
                                                    :title="'Ready Quantity'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                            </kendo-grid>
                        </div>
                    </CRow>
                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/production-card')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { Grid, GridColumn} from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import productionCardServices from '../Script/ProductionCardServices';
import { production } from '../../../../infrastructure/constant/variable';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
    name: 'ProductionCardFormPage',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
        'date-picker': datePicker
    },
    async mounted () {
        if(this.FormType == 'Booking'){
            var bookingData = await productionCardServices.getBookingOrderData(this.BookingId);

            this.ItemDisabled = true;
            this.MachineDisabled = false;
            this.EditableValue = true;

            this.ProductionCode = 'Auto';

            this.StatusData = [ {value: 'New', label: 'New'}];
            this.Status = 'New';

            this.ItemData = await productionCardServices.getItemMasterData(bookingData.item_id);
            this.Item = this.ItemData[0];

            this.MachineData = await productionCardServices.getMachineQuery();
            this.Machine = '';

            this.StorageData = await productionCardServices.getStorageQuery();
            this.Storage = '';

            this.Quantity = bookingData.quantity_base;

            this.WastePercentage = 0;

            this.BookingOrderCode = null;

            this.ProducerData = await productionCardServices.getProducerQuery();
            this.Producer = null;

            this.StartDateEstimate = '';
            this.EndDateEstimate = '';

            this.ProductionStartDate = '';
            this.ProductionFinishDate = '';

            //grid
            this.MaterialGridData = [];

            this.SaveType = 'Add';
            document.getElementById('ProductionCode').readOnly = false;
        }
        else if(this.FormType == 'Add'){
            this.ItemDisabled = false;
            this.MachineDisabled = false;
            this.EditableValue = true;

            this.ProductionCode = 'Auto';
            this.StatusData = [ {value: 'New', label: 'New'}];
            this.Status = 'New';

            this.ItemData = [];
            this.Item = '';

            this.MachineData = await productionCardServices.getMachineQuery();
            this.Machine = '';

            this.StorageData = await productionCardServices.getStorageQuery();
            this.Storage = '';

            this.Quantity = 1;

            this.WastePercentage = 0;

            this.BookingOrderCode = null;
            
            this.ProducerData = await productionCardServices.getProducerQuery();
            this.Producer = null;

            this.StartDateEstimate = '';
            this.EndDateEstimate = '';

            this.ProductionStartDate = '';
            this.ProductionFinishDate = '';

            //grid
            this.MaterialGridData = [];

            this.SaveType = 'Add';
            document.getElementById('ProductionCode').readOnly = false;
        }
        else {
            this.EditableValue = true;
            this.ProductionStartDateDisabled = false;

            var data = await productionCardServices.getDetailProductionCard(this.Id);
            this.ProductionCode = data.production_code;

            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_production');
            this.Status = data.status;

            this.ItemData = await productionCardServices.getItemFormulaQuery(data.machine_id);
            this.Item = this.ItemData.find(c => c.value == data.production_result_item_id);

            this.MachineData = await productionCardServices.getMachineQuery();
            this.Machine = this.MachineData.find(c => c.value == data.machine_id);

            this.StorageData = await productionCardServices.getStorageQuery();
            this.Storage = this.StorageData.find(c => c.value == data.storage_id);

            this.Quantity = data.production_result_quantity;

            this.WastePercentage = parseFloat(data.waste_percentage);

            this.BookingOrderCode = data.booking_order_id;

            this.ProducerData = await productionCardServices.getProducerQuery();
            this.Producer = this.ProducerData.find(c => c.value == data.producer_id);

            this.StartDateEstimate = moment(data.production_start_date_est).utc().format("YYYY-MM-DD HH:mm:ss");
            this.EndDateEstimate = moment(data.production_finish_date_est).utc().format("YYYY-MM-DD HH:mm:ss");
            

            var productionStartDate = data.production_start_date == null ? null : moment(data.production_start_date).format("YYYY-MM-DD HH:mm:ss");
            var productionFinishDate = data.production_finish_date == null ? null : moment(data.production_finish_date).format("YYYY-MM-DD HH:mm:ss");

            this.ProductionStartDate = productionStartDate;
            this.ProductionFinishDate = productionFinishDate;
            
            //grid
            this.MaterialGridData = productionCardServices.formulaCount(data.production_material, "Edit");

            if(this.ParamStatus == 'On Progress' || this.ParamStatus == 'Finish'){
                this.ActualQuantity = data.production_actual_quantity == null ? 0 : data.production_actual_quantity;
                this.RejectItemData = await productionCardServices.getAllItemQuery();
                this.RejectItem = this.RejectItemData.find(c => c.value == data.production_reject_item_id);
                this.RejectQuantity = data.production_reject_quantity == null ? 0 : data.production_reject_quantity;

                document.getElementById('Quantity').readOnly = true;
                document.getElementById('WastePercentage').readOnly = true;
                if (this.ParamStatus == 'Finish')
                    this.DisabledValue = true;
                if (this.ParamStatus == 'On Progress')
                    this.ProductionStartDateDisabled = true;
                this.ItemDisabled = true; 
                this.MachineDisabled = true;
            }

            this.SaveType = 'Edit';
            document.getElementById('ProductionCode').readOnly = true;

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Type: this.$route.params.type,
            ParamStatus: this.$route.params.status,
            BookingId: this.$route.params.bookingitemid,
            Error : 0,
            Internal : production.internal,
            External : production.external,

            ProductionCode: '',
            StatusData: [],
            Status: '',

            DisabledValue: false,
            
            ItemData: [],
            Item: '',
            ItemDisabled: false,

            MachineData: [],
            Machine: '',
            MachineDisabled: false,

            StorageData: [],
            Storage: '',
            Quantity: 1,

            WastePercentage: 0,
            BookingOrderCode: null,
            ProducerData: [],
            Producer: null,

            SaveType: '',
            EditableValue: false,
            GridReload: 0,

            ActualQuantity: 0,
            RejectItemData: [],
            RejectItem: null,
            RejectQuantity: 0,
            StartDateEstimate:'',
            EndDateEstimate:'',
            ProductionStartDate:'',
            ProductionFinishDate:'',
            ProductionStartDateDisabled : '',
            //Grid
            MaterialGridData: [],
            MaterialSchemaModel: {
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                qty: { type: "number", editable: true },
                actual_quantity: { type: "number", editable: true },
                ready_quantity : { type:"number", editable: false}
            },
            MaterialSchemaModelFinish: {
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                qty: { type: "number", editable: false },
                actual_quantity: { type: "number", editable: true },
                ready_quantity : { type:"number", editable: false}
            },
        }
    },
    methods: {
        async onChangeMachine(){
            if (this.Machine != null) {
                var itemFormulaData = await productionCardServices.getItemFormulaQuery(this.Machine.value);
                this.ItemData = itemFormulaData;
                this.MaterialGridData = [];
            }
            else {
                this.ItemData = [];
                this.Item = null;
                this.WastePercentage = 0;
                this.MaterialGridData = [];
            }

            //Booking Order Function
            if (this.FormType == 'booking') {
                if (this.Item != null || this.Item == '') {
                    var variables = {
                        id : this.Item.value,
                        // waste_percentage : parseFloat(this.WastePercentage),
                        quantity : parseFloat(this.Quantity)
                    };
                    var formulaRecipeData = await productionCardServices.getFormulaRecipeQuery(variables);

                    if (formulaRecipeData.length != 0) {
                        this.WastePercentage = formulaRecipeData[0].waste_percentage
                        this.MaterialGridData = formulaRecipeData;
                    }
                    else {
                        this.$swal("Error", "Item ini tidak memiliki Formula", "error");
                    }
                }
                else {
                    this.WastePercentage = 0;
                    this.MaterialGridData = [];
                }
            }
        },
        async onChangeItem(){
            if(this.Item != null || this.Item == ''){
                var variables = {
                    id : this.Item.value.toString(),
                    // waste_percentage : parseFloat(this.WastePercentage),
                    quantity : parseFloat(this.Quantity)
                };
                var formulaRecipeData = await productionCardServices.getFormulaRecipeQuery(variables);
                this.WastePercentage = formulaRecipeData[0].waste_percentage
                this.MaterialGridData = formulaRecipeData;
            }
            else{
                this.WastePercentage = 0;
                this.MaterialGridData = [];
            }
        },
        itemCellClose(e){
            this.GridReload++;
        },
        inputValidation(){
            this.Error = 0;
            $(".form-error").css("display", "none");

            //Validation
            if(this.ProductionCode == '' || this.ProductionCode == null){
                this.errorShow('errorProductionCode');
            }
            if(this.Item == null || this.Item == ''){
                this.errorShow('errorItem');
            }
            if(this.Type == this.External){
                if(this.Producer == null || this.Producer == ''){
                    this.errorShow('errorProducer');
                }
            }
            if(this.Machine == null || this.Machine == ''){
                this.errorShow('errorMachine');
            }
            if(this.Storage == null || this.Storage == ''){
                this.errorShow('errorStorage');
            }
            if(this.Quantity == '' || this.Quantity == 0){
                this.errorShow('errorQuantity');
            }
            if((this.StartDateEstimate == '' || this.StartDateEstimate == null) && this.ParamStatus == 'New'){
                this.errorShow('errorStartDateEstimate');
            }
            if((this.EndDateEstimate == '' || this.EndDateEstimate == null) && this.ParamStatus == 'New'){
                this.errorShow('errorFinishDateEstimate');
            }
            if((this.ProductionStartDate == null || this.ProductionStartDate == '' ) && this.ParamStatus == 'Approved'){
                this.errorShow('errorProductionStartDate');
            }
            if((this.ProductionFinishDate == '' || this.ProductionFinishDate == null) && this.ParamStatus == 'On Progress'){
                this.errorShow('errorProductionFinishDate');
            }
            
            var gridData = this.$refs.gridMaterial.kendoWidget().dataSource._data;
            if(gridData.length == 0){
                this.$swal("Error", "Material tidak boleh kosong", "error");
                this.Error++;
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
        saveClick(){
            this.inputValidation();
            
            if(this.Error == 0){
                //set grid
                var data = this.$refs.gridMaterial.kendoWidget().dataSource._data;
                var materialData = this.$globalfunc.objectToArrayConverter(data, "Production-ProductionMaterial");
                materialData = materialData.filter(function( obj ) {
                    return obj.quantity !== 0;
                });

                var producerId = null;
                if(this.Type == this.External){
                    producerId = parseInt(this.Producer.value)
                }

                const productionData = {
                    production_code: this.ProductionCode,
                    status: this.Status,
                    production_result_item_id: this.Item.value,
                    production_result_quantity: parseInt(this.Quantity),
                    production_material: materialData,
                    production_type: this.Type.toString(),
                    storage_id: this.Storage.value,
                    machine_id: parseInt(this.Machine.value),
                    waste_percentage: parseFloat(this.WastePercentage),
                    booking_order_id: this.BookingOrderCode == null ? null : parseInt(this.BookingOrderCode),
                    producer_id: producerId,
                    production_actual_quantity: this.ActualQuantity,
                    production_reject_item_id: this.RejectItem == null ? null : this.RejectItem.value,
                    production_reject_quantity: this.RejectQuantity,
                    production_start_date_est: this.StartDateEstimate,
                    production_finish_date_est: this.EndDateEstimate,
                    production_start_date: this.ProductionStartDate,
                    production_finish_date: this.ProductionFinishDate
                }
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : productionData
                    };

                    productionCardServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/production-card' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }else if (this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : parseInt(this.Id),
                        data : productionData
                    };

                    productionCardServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/production-card'
                                }
                            });                  
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        }
    } 
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>